import request from '@/auth/jwt/request'

/**
 * @description 话术分类管理 -- 话术分类管理列表
 * @param {Object} param params {Object} 传值参数
 */
export const talksCateListApi = params => { return request('words-category/list', 'get', params) }

/**
 * @description 话术分类管理 -- 增加修改话术分类
 * id = 0 新增
 * @param {Object} param params {Object} 传值参数
 */
export const talksCateSaveApi = data => { return request('words-category/save', 'post', {}, data) }

/**
 * @description 话术分类管理 -- 话术分类状态管理
 * @param {Object} param params {Object} 传值参数
 */
export const talksCateStatusApi = params => { return request('words-category/status', 'get', params) }

/**
 * @description 话术分类管理 -- 话术分类详情
 * @param {Object} param params {Object} 传值参数
 */
export const talksCateInfoApi = params => { return request('words-category/info', 'get', params) }
/**
 * @description 话术分类管理 -- 删除话术分类
 * { id = x }
 */
export const talksCateDeleteApi = data => { return request('words-category/delete', 'delete', data) }